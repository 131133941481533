/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Flex } from "@theme-ui/components"
import { i18nContext, languages } from "../context/i18nContext"
import Header from "./header"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import Hreflang from "./hreflang"
import Canonical from "./canonical"
import ChatBox from "./blocks/chatBox"
import MobileFooter from "./mobileFooter"
import Contact from "./contact"
import { Helmet } from "react-helmet"

const Layout = ({ children, locale, i18nPaths, location, theme, hideMenu }) => {
  const data = useStaticQuery(graphql`
    query SiteQuery {
      datoCmsSite: datoCmsSite {
        locale
      }
      gatsbySite: site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  return (
    <i18nContext.Provider
      value={languages[locale] || languages[data.datoCmsSite.locale]}
    >
      <LanguageSwitcherContext.Provider
        value={{ activeLocale: locale, paths: i18nPaths || [] }}
      >
        <Hreflang
          paths={i18nPaths}
          siteUrl={data.gatsbySite.siteMetadata.siteUrl}
        />
        <Canonical
          siteUrl={data.gatsbySite.siteMetadata.siteUrl}
          paths={i18nPaths}
        />
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "100vh",
          }}
        >
          <Header theme={theme} hideMenu={hideMenu} />
          <Box>{children}</Box>
          <Contact />
          <MobileFooter />
        </Flex>
        <ChatBox />
      </LanguageSwitcherContext.Provider>
    </i18nContext.Provider>
  )
}

export default Layout
